import "./newitem.scss"
import { Sidebar } from "../../components/sidebar/Sidebar"
import { Navbar } from "../../components/navbar/Navbar"
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import {functions} from '../../firebase';
import { useAuth } from "../../context/authContext";
import { Alert } from '../../components/alert/Alert';
import { DynamicRows } from '../../components/dynamicrows/DynamicRows';
import {useNavigate, Link, useLocation} from 'react-router-dom'
import { doc, onSnapshot} from 'firebase/firestore'
import {firestore} from '../../firebase';
//import { Link } from "react-router-dom";
import { useParams } from "react-router";
//import { DynamicCategory } from "../../components/dynamiccategory/DynamicCategory";
import FreeSoloCreateOption from "../../components/freesolo/FreeSoloCreateOption";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {addDoc, collection, query, where, getDocs, updateDoc} from 'firebase/firestore'
import Autocomplete from '@mui/material/Autocomplete';


export const NewItem = ({inputs}) => {
let test="sdsdsd";
  let url1 = window.location.href;
    const [address,setAddress] = useState("");

  let { docId, fromSidebar} = useParams();

  const [alert, setAlert] = useState({});

  const {state} = useLocation();
  
  
const {user} = useAuth();


const [item, setItem] = useState("");
const [description, setDescription] = useState("");
const [date, setDate] = useState("");
const [items, setItems] = useState([]);

const findItems = () => {
  const firestoreRef = collection(firestore,"items");
  const q = query(firestoreRef);
  const querySnapshot = getDocs(q)
  .then((response)=>{
    let arrayItems = [];
    response.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("query!!");
      console.log(doc.id, " => ", doc.data());
        arrayItems.push(doc.data());
      });
      console.log(arrayItems);
      setItems(arrayItems);
      console.log(items);
  })
  .catch((error)=>{
    setAlert({"message":error.message,"type":"error"});
  });
  
};

useEffect(() => {
  findItems();
  if(docId){
    const documentRef = doc(firestore,"items",docId);
        const unsubscribe = onSnapshot(documentRef, snapshot => {
            //setCustomers(snapshot.docs.map(doc=>({id:doc.id,data:doc.data()})))
            console.log("snapshot.data()");
            console.log(snapshot.data());
            //setDocument(snapshot.data());
            setItem(snapshot.data().item);
            setDescription(snapshot.data().description);
            let newDate = new Date();
            setDate(snapshot.data().date?snapshot.data().date:newDate.toLocaleString());
        });
        return()=>{
            unsubscribe();

        }
  }
}, []);

/*const setValueParent = (value) => {
  console.log("item:"+value.inputValue);
  setDocument({...document,["item"]: value.inputValue});
  

};*/


const findDescription = (value) => {
  console.log("findDescription"+value);
    
  if(!value) return;

  const found = items.find(obj => {
    return obj.item.includes(value);
  });

  console.log("found!!");
  //console.log(document);
  console.log(value);
  console.log(found);
  if(found){
    setDescription(found.description);
    let newDate = new Date();
    setDate(found.date?found.date:newDate.toLocaleString());
  }
  
};

const assignItem = (value) => {
  console.log("assignItem"+value);
  setItem(value);
  
  findDescription(value);
  
};

const findItem = () => {
  console.log("findItem"+item);
   
  findDescription(item);
  
};

const handleChange = ({target: {name, value}}) => {
  setAlert({});
  console.log(name+":"+value);
  //console.log(document);
  if(name=="item"){
    console.log("item!!!");
    setItem(value);
    //setDescription("");
    //findDescription(value);
  }

  if(name=="description"){
    setDescription(value);
  }
  

  
};
const handleSubmit = async (e) => {
  e.preventDefault();
  const firestoreRef = collection(firestore,"items");
  let newDate = new Date();
  let document = {};
        document.item = item;
        document.description = description;
        

           console.log(document);         

        const q = query(firestoreRef,where("item", '==', item));
        

        const querySnapshot = await getDocs(q);

        if(querySnapshot.size>0){
          querySnapshot.forEach((docQuery) => {
            const docRef = doc(firestore,"items",docQuery.id);
            document.update = newDate.toLocaleString();
            document.date = date;
            updateDoc(docRef,document).then(response=>{
                console.log(response);
                findItems();
                setAlert({"message":"Document was updated successfully","type":"success"});
            })
            .catch(error=>{
                console.log(error.message);
                setAlert({"message":error.message,"type":"error"});
            }); 
            console.log(docQuery.id, " => ", docQuery.data());
          });

             
            return;
        }
        
        //
        /*querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        });*/
        
       // return;
       //console.log(customer.id);
       //console.log("type:"+type1);
       const collectionRef = collection(firestore,"items");
        //setDoc will overwrite all document
        //collect.img=url;
        //console.log("collection doc");
        //console.log(collect);
        //document.status="pending";
        document.date = newDate.toLocaleString();
        document.update = newDate.toLocaleString();
        await addDoc(collectionRef,document).then(response=>{
            findItems();
            setAlert({"message":"Document was created successfully","type":"success"});
                   
        })
        .catch(error=>{
            console.log(error.message);
            setAlert({"message":error.message,"type":"error"});
        });
};


    return(
        <div className="newitem">
           
            <div className="newItemContainer">
                <Navbar/>
                {alert && <Alert alert={alert} />}
                
                <form >
               
                  
                 
                <div className="div1">
                <Autocomplete
                value={item?item:""}
                  id="free-solo-demo"
                  onChange={(event,newValue) => {
                    console.log("change autocomplete");
                    assignItem(newValue);
                    //console.log(this);
                    /*if(newValue)
                      if(newValue.length>0)
                        handleSelect(newValue);*/
                   
                    
                  }}
                 // onClose={(event) => {
                   // console.log("close autocomplete");
                   // handleSelect(inputValue);
                    //console.log(this);
                    /*if(newValue)
                      if(newValue.length>0)
                        handleSelect(newValue);*/
                   
                    
                  //}}
                  
                  freeSolo
                  style = {{width: "80%"}}
                  options={items?.map((option) => option.item)}
                  renderInput={(params) => <TextField {...params} value="sdsd" name="item" onChange={handleChange} label="Create or select the item" />}
                />
                <div className="button" onClick={findItem}>
                Find
                </div>
                    
                <TextField className="textfield"
                  id="outlined-multiline-static"
                  name="description"
                  label=""
                  multiline
                  rows={20}
                  style = {{width: "80%"}}
                  value={description?description:""}
                  onChange={handleChange}
                />  
                <div className="button">
                <button onClick={handleSubmit}>Save</button>
                
                </div>
                
                </div> 
                
                
                
                                
                </form>  
            </div>
        </div>
    )
}