export function Alert({alert}){
    //let color="yellow";
    //console.log("alert");
    //console.log(alert);
    if(typeof alert==='undefined'){
        console.log("alert undefined");
        return <></>;
    }
    if (Object.keys(alert).length === 0){
    //if(!alert.type){
        console.log("alert length==0");
        return <></>;

    }


    let properties = "bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-2 text-center";
    switch(alert.type){
        case "error":
            properties = "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2 text-center";
            break;
        case "success":
            properties = "bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-2 text-center";
            break;
        default:
            break;

    }
    
//console.log(properties);
    return <div className={properties}>
        <span className="sm:inline block">{alert.message}</span>
    </div>


}