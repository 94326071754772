import "./items.scss"
import { Sidebar } from "../../components/sidebar/Sidebar"
import { Navbar } from "../../components/navbar/Navbar"
import { Datatable } from "../../components/datatable/Datatable"



export const Items = ({inputs}) => {
    
    
    return(
        <div className="items">
            
            <div className="itemsContainer">
                <Navbar/>
                <Datatable inputs={inputs}/>
            </div>
        </div>
    )
}