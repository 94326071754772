import { Sidebar } from "../../components/sidebar/Sidebar"
import { Navbar } from "../../components/navbar/Navbar"
import "./update.scss"
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined"

import {useEffect,useState} from 'react'
import {updateDoc} from 'firebase/firestore'
import {firestore, storage} from '../../firebase';
import { doc, onSnapshot,collection,query,where,getDocs} from 'firebase/firestore'
//import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { Alert } from '../../components/alert/Alert';

export const Update = ({inputs}) => {
    const [alert, setAlert] = useState({});
    const [file, setFile] = useState("");
    //const [img, setImg] = useState(false);
    //const [image, setImage] = useState("");
    let { docId } = useParams();
    const [inputKey,setInputKey] = useState("");
    const [docRefId,setDocRefId]  = useState("");
    //console.log("init");
    //console.log(file);

    
    //const storageRef2 = storage.storage().ref('img-profile-'+customerId);
    //const imageRef = storage.ref();//storageRef.child('img-profile-'+customerId);

    // 'file' comes from the Blob or File API

    

    

    //storageRef.getDownloadURL
    /*storageRef2.getDownloadURL().then((url)=>{
        setImage(url);
    })*/


    //console.log("customer:"+customerId);
    const [document, setDocument] = useState(
        {
            /*sku:'',
            name:'',
            description:'',
            category:'',
            brand:'',
            model:'',
            color:'',
            proce:'',
            stock:'',
            img:''*/
            /*username: '',
            name:'',
            email: '',
            phone:'',
            address:'',
            country:'',
            password:'',
            img:'',
            status:'',
            age: ''*/
        }
    );
    //const [customer,setCustomer] = useState([]);
    useEffect(()=>{
        //console.log("useEffect 1");
        //console.log("customerId2:"+customerId);
        const q = query(collection(firestore, inputs["form"]), where(inputs["key"], "==", docId));

        getDocs(q).then(querySnapshot =>{
            querySnapshot.forEach((doc) => {
                setDocRefId(doc.id);
                console.log(doc.data());
                setDocument(doc.data());
                setInputKey(doc.data()[inputs["key"]]);
               });
            //console.log(doc);
            //console.log(doc.id, " => ", doc.data());
        });

        /*const docRef = doc(firestore,inputs["form"],docId);
        const unsubscribe = onSnapshot(docRef, snapshot => {
            //setCustomers(snapshot.docs.map(doc=>({id:doc.id,data:doc.data()})))
            //console.log(snapshot.data());
            setDocument(snapshot.data());
            setInputKey(snapshot.data()[inputs["key"]]);
        });
        return()=>{
            unsubscribe();

        }*/
        console.log("useEffect 1 end");
    },[docId]);

    useEffect(()=> {
        //console.log("useEffect 2");
        //console.log(customer);
        
    },[document]);

    

    const handleChange = ({target: {name, value}}) => {
        //console.log(name, value);
        //console.log("handleChange");
        setDocument({...document,[name]: value});
        //console.log("handleChange end");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        setAlert({});
       console.log("docRefId:"+docRefId);
       const docRef = doc(firestore,inputs["form"],docRefId);
       
       const validate = inputs["formInputs"].every((element,index)=>{
        console.log(element.id+":"+element.label);
        if(element.required){
            
            if(!document[element.id]){
                //console.log("test");
                
                setAlert({"message":element.label+"is required","type":"error"});
                //console.log(element.label);
                return false;
                //return false;
            }
        }
        return true;
        });

        if(!validate){
            console.log("return");
            return;
        }
        //console.log(document.img);
        /*if(!file){
            setAlert({"message":"Image is required","type":"error"});
                    //console.log(element.label);
            return;
        }*/
        console.log("inputKey:"+inputKey);
        console.log("document[inputs[key]]:"+document[inputs["key"]]);
        if(inputKey!=document[inputs["key"]]){
            const firestoreRef = collection(firestore,inputs["form"]);
            const q = query(firestoreRef,where(inputs["key"], '==', document[inputs["key"]]));
            console.log();

            const querySnapshot = await getDocs(q);

            if(querySnapshot.size>0){
                //alert("document already exists");
                setAlert({"message":'Document already exists',"type":"error"});
                return;
            }
        }
        document.status="active";
        if(file){
            const storageRef = ref(storage, 'img-profile-'+docId);
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then( url => {
                    setDocument({...document,["img"]: url});
                    document.img = url;
                    //const docRef = doc(firestore,inputs["form"],docId);
                    updateDoc(docRef,document).then(response=>{
                        console.log(response);
                        setAlert({"message":'Document was updated successfully',"type":"success"});
                    })
                    .catch(error=>{
                        console.log(error.message);
                        setAlert({"message":error.message,"type":"error"});
                    });  
                });
                console.log('Uploaded a blob or file!');
            });
        }
        else{
            updateDoc(docRef,document).then(response=>{
                //console.log(response);
                setAlert({"message":'Document was updated successfully',"type":"success"});    
            })
            .catch(error=>{
                console.log(error.message);
                setAlert({"message":error.message,"type":"error"});
            }); 
        }
       
            
        return;
       /*const storageRef = ref(storage, 'img-profile-'+customer.username);
       
       console.log("handleSubmit");
       if(img){
        uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then( url => {
                    console.log(url);
                    //setImage(url);
                    setCustomer({...customer,["img"]: url});

                    console.log("customer doc");
                    console.log(customer);
                    customer.img=url;
                    customer.status="active";
                    updateDoc(docRef,customer).then(response=>{
                        console.log(response);
                    })
                    .catch(error=>{
                        console.log(error.message);
                    });
                });
                console.log('Uploaded a blob or file!');
            });
            return;
        }*/
        
        
                //setDoc will overwrite all document
                //customer.img=url;
                   

        
        console.log("handleSubmit end");   
           
    }
    return(
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <Navbar/>
                {alert && <Alert alert={alert} />}
                <div className="top">
                    <h1>{inputs["title"]}</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        
                        <img src={document.img 
                        ? document.img
                        :"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAMFBMVEXU1NT////Y2Nj7+/va2trm5ubz8/Pf39/29vbe3t7j4+P8/Pzt7e3Z2dn09PTp6enlgXfuAAAEj0lEQVR4nO2dCZarOgxEMVPCkGT/u31N8+mEEIIHVUmf47sC6ghNRhZFkclkMplMJpPJZDKZTCaTyWQymUwmk8lsKLuu75sf+r7rSu2niaNrxrZyK6p2bDrt5wqibtrB7TC0Ta39fH6Uj+ueiIXrw/5r1rdHKmbaXvtJv9JUxxL+PKbRfto9yhAZsxSTb1gfKONXir0XrPb0jXdaYyHssRtujxge2s/+wu0w4H7jetN+/oU+2hz/GcWIp4xpMiZGbQ0TkV6+ptVWUZR3CR3O3ZVTSpnk5q9cVZWUEUlwj0pRiZw9JhRtIuQfC3ctHSLx6hWl2PWQ1uGcSrlykdfh3IWvQzJgPVEIXeIOMkN3kwajwzlyA1wmFrz7DNyXS6Di3YNaCXc4Hc4xDyNFS5N3rjwdPVKHc7yGEWoQokkgOf0VVn4HG4RmEmjImuEELmAOWeDkEki1uKZi6ADH3hlGBAaVvWsYRTCsXHxlwOuAJ5EZfCoBdOqfwHfv8Gw4A8+JJUeHc+j+iuQieCeB9ervoHt3Qn0yg65SKOlwAp0SCYXWDLrcYulwDquDFn3R8bfmCcGORBC6wwVsl3gaIbTEjk7tlPZwBtsknsYip/GR0wg5TR45TYlynqKR1LLjm/bT9COk0yD8edBpDh9OcxzEClv4DwukYxT8px5S/Yv/QEJyEsJECiUlMr7rUg5NGcNOlHeLMutEqFI4c3SEuEUaq4HnRMpn9oLg7qy5RtxA4wxvrBFcy/PmsTHDywvMIWaol1Anf4F1CnE2s4Ae1JGv7sPaEvZNPpS/868r1JBkMijcQYaUXCqXXQFuonTVVTwGcyPvE2mH17tS2Yk6/KC4/KWTvOKqusSmFlNSKS9/kFKiraMobiJKKgN7HySuUOteZv8jOTOaWPkwcUl6vSqFC7p7lAmHdq2N12ohdjeKlZ0oT25RnjIaiFYbuuDwdbW6ke4S5CqtISff0Hi7ymB24VlR9mNQGK7G3lbA+qVsonaL3I1tb/PdBfgJO/sB67A3aks1qpe+P1xE1tXctSPYRW6bk6aUXnYJkpazyFnjT4qGVW6Qr9QtvfaKX8z4HfLaxph1n74Q14KmtFE+sFqttMbWB07zSxmhwx9H1KxLx+CqJXVtqT/YZp42vjwBDMS0i7ozKEeRXS/pA+YkVe4Lgj+IM3oNHQglOjrklWjpkFYi+a0wWIngcaSePX6ViNkEOzDnoUQoCvPzxztC+YR2P2wfkclscl3yGYFqhbbR5TvJZ/fEW8bfSQzC2gHrSWLoMuDoC0kOb8RBZhLcBDOAGUvC4KZ6JlwTPSlI7dB9iOzibb1YE5Evl6GItRAVuYi7XPyJOOyykwpfiUiLJmrFLcHVI/pCWCzBF8mMGiTYJFYNEmwSswYJNMnNrEF+TBLy4dewQYJMYtdDJgK8xFy1uMa/djSZ1J943xInLpqLw/frtcGyd41nEUzcVxqLn7sbd/UJP3c31ql/wqt7Jy7+i8en5zV1lrWHzxmX8E8OMXj8OvF/ELMmjuOWyTOHLcenEOaz4cxxTjRd+D7Z/KDkH+MbT03dnEr6AAAAAElFTkSuQmCC" 
                        }
                        alt="" />
                    </div>
                    <div className="right">
                        <form >
                        <div className="formInput">
                                
                                <label htmlFor="img">Image: <DriveFolderUploadOutlinedIcon className="icon"/></label>
                                <input 
                                type="file" 
                                id="img" 
                                name="img"
                                onChange={(e)=>{
                                    console.log(e.target.files[0]);
                                    setFile(e.target.files[0]);
                                    //setImg(true);
                                }}
                                style={{display:"none"}}/>
                            </div>

                            {inputs["formInputs"].map((input) =>(
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input 
                                    name={input.id} 
                                    type={input.type} 
                                    placeholder={input.placeholder} 
                                    value={document[input.id]?document[input.id]:""}
                                    onChange={handleChange}/>
                                </div>
                            ))}

                            <button onClick={handleSubmit}>Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}