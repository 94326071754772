import "./navbar.scss"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined"
import ListOutlinedIcon from "@mui/icons-material/ListOutlined"
import { DarkModeContext } from "../../context/darkModeContext"
import { useContext } from "react"
import { useAuth } from "../../context/authContext"
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreateIcon from '@mui/icons-material/Create';
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

export const Navbar = () => {
    const {dispatch} = useContext(DarkModeContext)
    const {user, logout, loading} = useAuth()
    //const navigate = useNavigate();
    console.log("user");
    console.log(user);
    console.log(user.email);
    console.log(user.uid);

    const handleLogout = async () =>{
        try{
            await logout();
            //navigate('/login');
        }
        catch (error){
            console.log(error);
        }
    }

    return(
        <div className="navbar">
            <div className="wrapper">
                <div className="search">
                    {/* <input type="text" placeholder="Search..." />
                    <SearchOutlinedIcon className="icon"/> */}
                    <Link to="/items/new" state={{fromSidebar:1}}>
                    
                    <CreateIcon className="icon"/>
                        
                    
                    </Link>
                    
                    <Link to="/items">
                    
                        <AccountTreeIcon className="icon"/>
                        
                    </Link>
                </div>
                <div className="itemsNavbar">
               
                    {/* <div className="itemNavbar">
                    <LanguageOutlinedIcon className="icon"/>
                    English
                    </div>
                    <div className="itemNavbar">
                    <DarkModeOutlinedIcon className="icon" onClick={()=>dispatch({type:"TOGGLE"})}/>
                    
                    </div>
                    <div className="itemNavbar">
                    <FullscreenExitOutlinedIcon className="icon"/>
                    
                    </div>
                    <div className="itemNavbar">
                    <NotificationsNoneOutlinedIcon className="icon"/>
                    <div className="counter">1</div>
                    </div>
                    <div className="itemNavbar">
                    <ChatBubbleOutlineOutlinedIcon className="icon"/>
                    <div className="counter">2</div>
                    </div>
                    <div className="itemNavbar">
                    <ListOutlinedIcon className="icon"/>
                    
                    </div> */}
                    <div className="itemNavbar">
                    <p>{user.email}</p>
                    
                    </div>
                    <div className="itemNavbar">
                    {user.photoURL?
                    <img src={user.photoURL} className="avatar"/>
                    :
                    <div className="firstLetter">{user.email[0].toUpperCase()}</div>
                    }
                    <LogoutIcon className="icon" onClick={handleLogout}/>
                        
                    
                    </div>
                    
                </div>
            </div>

        </div>
    )
}