
export const tableColumns = 
    //{field: "id", headerName: "ID", width: 70},
    {"users":[
        {field: "id", headerName: "", width: 100, hide:true},
        {field: "username", headerName: "User", width:160, renderCell:(params)=>{
            return(
                <div className="cellWithImg">
                    <img className="cellImg" src={params.row.img} alt="avatar"/>
                    {params.row.username}
                </div>

            )
            }
        },
        {field: "name", headerName: "Name", width: 230},
        {field: "email", headerName: "Email", width: 230},
        //{field: "age", headerName: "Age", width: 100},
        {field: "status", headerName: "Status", width: 160, renderCell:(params)=>{
            return(
                <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>

            )
            }
        },
   
    ]
    ,"products":[
        {field: "id", headerName: "", width: 100, hide:true},
        {field: "sku", headerName: "SKU", width:220, renderCell:(params)=>{
            return(
                <div className="cellWithImg">
                    <img className="cellImg" src={params.row.img} alt="avatar"/>
                    {params.row.sku}
                </div>

            )
            }
        },
        {field: "name", headerName: "Name", width: 230},
        {field: "description", headerName: "Description", width: 230},
    ]
    ,"plans":[
        {field: "id", headerName: "", width: 100, hide:true},
        {field: "name", headerName: "Plan", width: 230},
        {field: "description", headerName: "Description", width: 230},
    ]
    ,"items":[
        {field: "item", headerName: "Item", width: 230},
        {field: "description", headerName: "Description", width: 230},
        {field: "date", headerName: "Date", width: 180},
        {field: "update", headerName: "Update", width: 180},
    ]
    }
//temporary data
/*export const userRows = [
    {
        id:1,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"active",
        email:"1snow@gmail.com",
        age:35
    },
    {
        id:2,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"pending",
        email:"1snow@gmail.com",
        age:35
    },
    {
        id:3,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"passive",
        email:"1snow@gmail.com",
        age:35
    },
    {
        id:4,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"pending",
        email:"1snow@gmail.com",
        age:35
    },
    {
        id:5,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"active",
        email:"1snow@gmail.com",
        age:35
    },
    {
        id:6,
        username:"Snow",
        img:"https://yt3.ggpht.com/yti/AJo0G0nCLFCcCmSN_3iWPnigtmMWdFydtmfwXPUHOI6T=s88-c-k-c0x00ffffff-no-rj-mo",
        status:"active",
        email:"1snow@gmail.com",
        age:35
    }

]*/