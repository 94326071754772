import "./datatable.scss"
import { DataGrid } from '@mui/x-data-grid';
import {tableColumns/*, userRows*/} from "../../datatablesource";
//import {Crud} from "../crud/Crud";
import { useEffect, useState } from 'react'
import {collection, doc, deleteDoc,onSnapshot} from 'firebase/firestore'
import {firestore} from '../../firebase';
//import {AddCustomer} from '../customer/AddCustomer';
//import {UpdateCustomer} from '../customer/UpdateCustomer';
import { Link } from "react-router-dom";
import { Alert } from '../../components/alert/Alert';
import { useAuth } from "../../context/authContext"

export const Datatable = ({inputs}) => {
    const collectionRef = collection(firestore, inputs["form"])
    const {user, /*logout,*/ loading} = useAuth()
    const [documents,setDocuments] = useState([]);
    const [alert,setAlert] = useState({});
    //console.log("param1:"+param1);
   /* if(param1=="1"){
        console.log("test1!!!!!!!!!!!!!!!!!!!!!11");
        param1 = "2";
    }*/
    

    let url1 = window.location.href;
    const [address,setAddress] = useState("");
    useEffect(()=> {
        if(address==""){
            setAddress(url1);
        }
        
        //console.log(address);
        
    },[]);

    function assignDocuments(snapshot){
        console.log(snapshot);
            let updateRows = [];
            /*snapshot.docs.map(doc=>{
                updateRows = doc.data();
                updateRows.id = doc.id;

            });*/
            for(var key in snapshot.docs){
                if(snapshot.docs.hasOwnProperty(key)){
                    const obj = snapshot.docs[key].data();
                    obj.id = snapshot.docs[key].id;
                    //obj.action = "<button>Delete</button>";
                    updateRows.push(obj);
                }
               
                
            }
            //console.log("updateRows");
            //console.log(updateRows);
            //let test2 = {};
            //test2[type1] = updateRows;
            setDocuments(updateRows);
    }
    //fix to solve the issue when the user select products and the next page selected is customers, keep the value of collects
    if(address!="" && url1 != address){
        //console.log("urls diferentes!!!!!!!!!!!!!!!");
        //window.location.reload(true);
        
        setAddress(url1);
        //const collectionRef = collection(firestore, type1)
        const unsubscribe1 = onSnapshot(collectionRef, snapshot => {
            //setCustomers(snapshot.docs.map(doc=>({id:doc.id,data:doc.data()})))
            assignDocuments(snapshot);
        });
        
    }
    //console.log("url:"+url1);
    //console.log("address:"+address);
    //console.log("entrando a datatable" + type1)
    //const [test, setTest] = useState("");
    /*let test1 = {};
    test1[type1] = [];*/
    
    //const [loadData, setLoadData] = useState(false);
    //setCollects([]);
    //let userRows = [];
    /*console.log("loadData:"+loadData);
    if(!loadData){
        setLoadData(true);
    }*/
    //console.log("type"+type1);
    //console.log(collects);
    useEffect(()=>{
        //console.log("param1 useeffect:"+param1);
        //console.log("useEffect");
        let form = inputs["form"];
        if(inputs["tenant"]=="yes"){
             form = inputs["form"] + "-" + user.uid;
            console.log("new form:"+inputs["form"]);
        }
        const collectionRef = collection(firestore, form)
        const unsubscribe = onSnapshot(collectionRef, snapshot => {
            //setCustomers(snapshot.docs.map(doc=>({id:doc.id,data:doc.data()})))
            assignDocuments(snapshot);
            /*setCustomers(snapshot.docs.map(doc=>({
                id:doc.id,
                username:doc.data().username,
                img:doc.data().img,
                name:doc.data().name,
                email:doc.data().email,
                phone:doc.data().phone,
                address:doc.data().address,

                status:doc.data().status,
                
                age:doc.data().age
            })))*/
        });
        return()=>{
            unsubscribe();

        }
    },[]);
    
    useEffect(()=> {
        
        //console.log(collects);
        
    },[documents]);

    /*function getCustomers(){
        const usersCollectionRef = collection(firestore, 'customers')
        getDocs(usersCollectionRef).then(response => {
            
            const custs = response.docs.map(doc => ({data: doc.data(), id:doc.id}));
            setCustomers(custs);
            
            
        })
        .catch(error => console.log(error.message))
        ;
        
    }*/

    function deleteDocument(id){
        console.log("id:"+id);
        console.log(inputs);
        let form = inputs["form"];
        if(inputs["tenant"]=="yes"){
             form = inputs["form"] + "-" + user.uid;
            console.log("new form:"+inputs["form"]);
        }
        const docRef = doc(firestore,form,id);
        //setDoc will overwrite all document
        deleteDoc(docRef).then(()=>setAlert({"message":"Document was deleted","type":"success"}))
        .catch(error=>setAlert({"message":error.message+"is required","type":"error"}));
    }

    /*for(var key in customers){
        if(customers.hasOwnProperty(key)){
            const obj = customers[key].data;
            obj.id = customers[key].id;
            //obj.action = "<button>Delete</button>";
            userRows.push(obj);
        }
       
        
    }*/
    const actionColumn = [{field: "action", headerName:"Action", width:160, renderCell:(params)=>{
        return(
            <div className="cellAction">
                <div className="viewButton" ><Link to={`/${inputs["form"]}/${params.row.id}`}>View</Link></div>
                <div className="deleteButton" onClick={()=>deleteDocument(params.row.id)}>Delete</div>
            </div>
        )
    }}];
    //columns={userColumns.concat(actionColumn)}
    /*<DataGrid
        rows={userRows}
        columns={userColumns.concat(actionColumn)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
      <AddCustomer/>
      <UpdateCustomer/> */
      /*<div className="datatable">
            <ul>
                {customers.map(customer=>(
                    <li key={customer.id}>
                        {customer.id} : {customer.data.username}
                    </li>
                ))}
            </ul>
        
        </div>*/
        
    return(
        <div className="datatable">
            {alert && <Alert alert={alert} />}
            <div className="datatableTitle">
                {inputs["title"]}
                <Link to="/items/new" state={{fromSidebar:1}} className="link">
                    Add New
                </Link>
            </div>
        <DataGrid className="datagrid"
        rows={documents}
        columns={tableColumns[inputs["form"]].concat(actionColumn)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      /> 
      </div>
    )
}