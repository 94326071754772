import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ViewListIcon from '@mui/icons-material/ViewList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EmailIcon from '@mui/icons-material/Email';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreateIcon from '@mui/icons-material/Create';
import { useAuth } from "../../context/authContext"
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";



export const Sidebar = () => {
    const {dispatch} = useContext(DarkModeContext)
    const {user, logout, loading} = useAuth()
    
    

    const handleLogout = async () =>{
        try{
            await logout();
            //navigate('/login');
        }
        catch (error){
            console.log(error);
        }
    }
    /*const navigate = useNavigate();
   
    const handleChange = (e) => {
        e.preventDefault();
        const name = e.target.getAttribute("name");
        console.log(name);
        if(name=="dashboard") navigate("/");
        else navigate("/"+name);
       
    }*/
    //console.log("test");
    if(loading) return <h1>Loading</h1>;

    
    return(
        <div className="sidebar">
            <div className="top">
            <Link to="/">
                <span className="logo">help</span>
                </Link>
            </div>
            <hr />
            <div className="center">
                <ul>
                    
                    <p className="title">MAIN</p>
                    <Link to="/items/new" state={{fromSidebar:1}}>
                    <li>
                    <CreateIcon className="icon"/>
                        <span>New item</span>
                    </li>
                    </Link>
                    <Link to="/items">
                    <li>
                        <AccountTreeIcon className="icon"/>
                        <span>
                        Items
                        </span>
                    </li>
                    </Link>
                                   
                    
                    <p className="title">USER</p>
                    <Link to={`/users/${user.uid}`}>
                    <li>
                    <AccountBoxIcon className="icon"/>
                        <span>Profile</span>
                    </li>
                    </Link>
                    <li>
                    <ExitToAppIcon className="icon"/>
                        <span onClick={handleLogout}>Logout</span>
                    </li>
                   
                </ul>
            </div>
            <div className="bottom">
                <div className="colorOption" onClick={()=>dispatch({type:"LIGHT"})}></div>
                
                <div className="colorOption" onClick={()=>dispatch({type:"DARK"})}></div>
            </div>
        </div>
    )
}
