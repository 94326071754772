import { useAuth } from "../../context/authContext";
import {Navigate} from 'react-router-dom';
export function ProtectedRoute({children}){
    const {user, loading} = useAuth();
    console.log("protectedRoute");
    console.log("loading:"+loading);
    console.log(user);
    if(loading) return <h1>Loading</h1>;
    if(!user) return <Navigate to='/login'/>;

    return <>{children}</>;
}