import {createContext, useContext, useEffect, useState} from 'react';
import {
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail,
} from 'firebase/auth'
import {auth} from '../firebase'
export const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);

    if(!context) throw new Error('The is not aut provider');
    return context;

}



 export function AuthProvider({children}){
     /*const user = {
         login: true
     }*/
     const [user, setUser] = useState(null);
     const [loading, setLoading] = useState(true);
     const signup = (email, password) => createUserWithEmailAndPassword(auth,email,password);
     /*const login = async (email,password) => {
        const userCredentials = await signInWithEmailAndPassword(auth, email, password);
        console.log(userCredentials);
     }*/
     const delay = ms => new Promise(res => setTimeout(res, ms));
     const login = async (email,password) => {
        setLoading(true);
        const signIn = await signInWithEmailAndPassword(auth, email, password);
        
        console.log("signIn");
        console.log(signIn);
        //should wait the validation make in asyncHandler
        await delay(1000);
        console.log("checking loading const 1");
        if(!loading) return("ok");
        await delay(1000);
        console.log("checking loading const 2");
        if(!loading) return("ok");
        await delay(1000);
        console.log("checking loading const 3");
        if(!loading) return("ok");
        
        console.log("login end");
        return("error");
     }
     
     const logout = () => signOut(auth);

     const loginWithGoogle = () => {
        const googleProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleProvider);
     }

     const resetPassword = (email) => {
        console.log(email);
        return sendPasswordResetEmail(auth, email);
     }

    async function asyncHandler(currentUser){
        if(currentUser){
            await currentUser.getIdToken().then(async function(idToken) {  // <------ Check this line
               console.log(idToken); // It shows the Firebase token now
               var myHeaders = new Headers();
               var authorization = "Bearer "+idToken;
               myHeaders.append("Authorization", authorization);

               var raw = JSON.stringify({
                   "idToken": idToken
               });

               var requestOptions = {
               method: 'POST',
               headers: myHeaders,
               body: raw,
               redirect: 'follow'
               };
               
               /*await fetch("http://127.0.0.1:5001/pegasi-f7779/us-central1/validate", requestOptions)
               //await fetch("https://us-central1-carinae-d609f.cloudfunctions.net/validate", requestOptions)
               .then(response => response.text())
               .then(result => {
                   console.log(result);
                   setUser(currentUser);
                   setLoading(false);
               })
               .catch(error => {
                   console.log('error', error);
                   setUser(null);
                   setLoading(false);
               });*/
           
           });
       }
       else{
           setUser(currentUser);
           setLoading(false);
       }
       
       console.log("asyncHandler end");
    }

     useEffect(() => {
        //console.log('auth provider loaded');
        /*async function asyncHandler(user1){

        }*/
        const unsubscribe = onAuthStateChanged(auth, async currentUser => {
            setUser(currentUser);
            setLoading(false);
            //console.log("onAuthStateChanged start");
            //await asyncHandler(currentUser);
            //console.log("onAuthStateChanged end");
            /*if(currentUser){
                 await currentUser.getIdToken().then(function(idToken) {  // <------ Check this line
                    console.log(idToken); // It shows the Firebase token now
                    var myHeaders = new Headers();
                    var authorization = "Bearer "+idToken;
                    myHeaders.append("Authorization", authorization);

                    var raw = JSON.stringify({
                        "idToken": idToken
                    });

                    var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };

                    fetch("http://127.0.0.1:5001/carinae-d609f/us-central1/validate", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        setUser(currentUser);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log('error', error);
                        setUser(null);
                        setLoading(false);
                    });
                
                });
            }
            else{
                setUser(currentUser);
                setLoading(false);
            }*/
            
        });
        
        return () => unsubscribe();
     },[])
     
     return(
         <authContext.Provider value={{signup, login, user, logout, loading, loginWithGoogle, resetPassword}}>
             {children}
         </authContext.Provider>
     )

}