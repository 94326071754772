import {Routes,Route} from "react-router-dom"
import { Home } from "./pages/home/Home";
import { List } from "./pages/list/List";
import { Single } from "./pages/single/Single";
import { New } from "./pages/new/New";
import { Login } from "./pages/login/Login";
import { ProtectedRoute } from "./components/protected-route/ProtectedRoute";
import { Register } from "./pages/register/Register";
import {AuthProvider} from "./context/authContext"
import {userInputs,itemsInputs,plansInputs} from "./formSource"

import "./styles/dark.scss"
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { Update } from "./pages/update/Update";
import { Items } from "./pages/items/Items";
import { NewItem } from "./pages/items/NewItem";

function App() {
  console.log("app");

  const {darkMode} = useContext(DarkModeContext)

  return (
    // <div className="bg-white-300 h-screen text-black flex" >
    <div className={darkMode ? "app dark" : "app"} >
      <AuthProvider>
         <Routes>
            <Route path="/" element={<ProtectedRoute><NewItem/></ProtectedRoute>} />
            <Route path="/plans">
              <Route index element={<ProtectedRoute><List inputs = {plansInputs}/></ProtectedRoute>} />
              
            </Route>

            <Route path="/users">
              <Route index element={<ProtectedRoute><List inputs = {userInputs}/></ProtectedRoute>} />
              <Route path=":docId" element={<ProtectedRoute><Single inputs = {userInputs}/></ProtectedRoute>} />
              <Route path="new" element={<ProtectedRoute><New inputs = {userInputs} /></ProtectedRoute>} />
              <Route path="update/:docId" element={<ProtectedRoute><Update inputs = {userInputs}/></ProtectedRoute>} />
            </Route>
            
            <Route path="/items">
              <Route index element={<ProtectedRoute><Items inputs = {itemsInputs}/></ProtectedRoute>} />
              <Route path=":docId" element={<ProtectedRoute><NewItem/></ProtectedRoute>} />
              <Route path="new" element={<ProtectedRoute><NewItem/></ProtectedRoute>} />
            </Route>
            
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} /> 
         </Routes>
      </AuthProvider> 
    </div>
  )
}


export default App;
