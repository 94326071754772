import "./single.scss"
import { Sidebar } from "../../components/sidebar/Sidebar"
import { Navbar } from "../../components/navbar/Navbar"
import { Chart } from "../../components/chart/Chart"
import { List } from "../../components/list/List"
import { useEffect, useState } from 'react'
import { doc, onSnapshot} from 'firebase/firestore'
import {firestore} from '../../firebase';
//import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { collection, query, where, getDocs } from "firebase/firestore";

export const Single = ({inputs}) => {
    let { docId } = useParams();
    console.log(docId);
    const [document,setDocument] = useState([]);
    
    //let userRows = [];
    useEffect(()=>{
        //console.log("userId"+userId);
        const q = query(collection(firestore, inputs["form"]), where(inputs["key"], "==", docId));

        getDocs(q).then(querySnapshot =>{
            querySnapshot.forEach((doc) => {
                console.log(doc.data());
                setDocument(doc.data());
               });
            //console.log(doc);
            //console.log(doc.id, " => ", doc.data());
        });
        /*querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
            setDocument(doc.data());
        });*/

        /*const documentRef = doc(firestore,inputs["form"],docId);
        const unsubscribe = onSnapshot(documentRef, snapshot => {
            //setCustomers(snapshot.docs.map(doc=>({id:doc.id,data:doc.data()})))
            //console.log(snapshot.data());
            setDocument(snapshot.data());
        });
        return()=>{
            unsubscribe();

        }*/
    },[docId]);

    useEffect(()=> {
       
        //console.log(customer);
        
    },[document]);

    return(
        <div className="single">
            <Sidebar/>
            <div className="singleContainer">
                <Navbar/>
                <div className="top">
                    <div className="left">
                        <div className="editButton"><Link to={`/${inputs["form"]}/update/${docId}`}>Edit</Link></div>
                        <h1 className="title">Information</h1>
                        <div className="item">
                            {document.img?
                            <img src={document.img} 
                            alt="" 
                            className="itemImg" />
                            :
                            <div className="itemNoImg">{document[inputs["name"]]?document[inputs["name"]].toUpperCase():""}</div>
                            }
                            <div className="details">
                                <h1 className="itemTitle">{document[inputs["name"]]}</h1>
                                {inputs["formInputs"].map((input) =>(
                                <div className="detailItem" key={input.id}>
                                <span className="itemKey">{input.label}:</span>
                                <span className="itemValue">{document[input.id]}</span>
                                </div>
                                
                                ))}
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="right">
                        <Chart aspect={3/1} title="User Spending (Last 6 Months)"/>
                    </div>
                </div>
                <div className="bottom">
                <h1 className="title">Last Transactions</h1>
                    <List/>
                </div>
            </div>
        </div>
    )
}