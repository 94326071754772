import { Sidebar } from "../../components/sidebar/Sidebar"
import { Navbar } from "../../components/navbar/Navbar"
import "./new.scss"
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined"

import {useState} from 'react'
import {addDoc, collection, query, where, getDocs, doc, updateDoc} from 'firebase/firestore'
import {firestore, storage} from '../../firebase';
import { ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { CollectionsBookmarkOutlined, InsertChartOutlinedSharp } from "@mui/icons-material"
import { Alert } from '../../components/alert/Alert';


export const New = ({inputs}) => {
    const [alert, setAlert] = useState({});
    const [file, setFile] = useState("");
    /*let fields = {};
    console.log(inputs);
    for(var key in inputs["formInputs"]){
        fields[inputs["formInputs"][key].id] = '';
        
    }
    //console.log("type 1:"+type1);
    //console.log(file);
    //console.log(fields);*/
    const [document, setDocument] = useState(
        {
           
           
        }
    );
    //console.log(document);
 /*username: '',
            name:'',
            email: '',
            phone:'',
            address:'',
            country:'',
            password:'',
            img:'',
            status:'',
            age: ''*/
    const handleChange = ({target: {name, value}}) => {
        //console.log(name, value);
        setDocument({...document,[name]: value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAlert({});
        
        const validate = inputs["formInputs"].every(element=>{
            console.log(element.id+":"+element.label);
            if(element.required){
                
                if(!document[element.id]){
                    //console.log("test");
                    setAlert({"message":element.label+"is required","type":"error"});
                    //console.log(element.label);
                    return false;
                    //return false;
                }
            }
            return true;
        });

        if(!validate){
            console.log("return");
            return;
        }
        //console.log(document.img);
        if(!file){
            setAlert({"message":"Image is required","type":"error"});
                    //console.log(element.label);
            return;
        }
        const firestoreRef = collection(firestore,inputs["form"]);
        const q = query(firestoreRef,where(inputs["key"], '==', document[inputs["key"]]));
        console.log();

        const querySnapshot = await getDocs(q);

        if(querySnapshot.size>0){
            //alert("document already exists");
            setAlert({"message":'Document already exists',"type":"error"});
            return;
        }
        
        //
        /*querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        });*/
        
       // return;
       //console.log(customer.id);
       //console.log("type:"+type1);
       const collectionRef = collection(firestore,inputs["form"]);
        //setDoc will overwrite all document
        //collect.img=url;
        //console.log("collection doc");
        //console.log(collect);
        document.status="pending";
                    
        await addDoc(collectionRef,document).then(response=>{
            //console.log(response.id);
            const storageRef = ref(storage, 'img-profile-'+response.id);
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then( url => {
                    setDocument({...document,["img"]: url});
                    document.img = url;
                    const docRef = doc(firestore,inputs["form"],response.id);
                    updateDoc(docRef,document).then(response=>{
                        console.log(response);
                        setAlert({"message":"Document was created successfully","type":"success"});
                    })
                    .catch(error=>{
                        console.log(error.message);
                        setAlert({"message":error.message,"type":"error"});
                    });  
                });
                console.log('Uploaded a blob or file!');
            });
        })
        .catch(error=>{
            console.log(error.message);
            setAlert({"message":error.message,"type":"error"});
        });

        
        //{alert && <Alert message={alert} />}
    }
    return(
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <Navbar/>
                {alert && <Alert alert={alert} />}
                <div className="top">
                    <h1>{inputs["title"]}</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        
                        <img src={document.img 
                        ? document.img
                        :"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAMFBMVEXU1NT////Y2Nj7+/va2trm5ubz8/Pf39/29vbe3t7j4+P8/Pzt7e3Z2dn09PTp6enlgXfuAAAEj0lEQVR4nO2dCZarOgxEMVPCkGT/u31N8+mEEIIHVUmf47sC6ghNRhZFkclkMplMJpPJZDKZTCaTyWQymUwmk8lsKLuu75sf+r7rSu2niaNrxrZyK6p2bDrt5wqibtrB7TC0Ta39fH6Uj+ueiIXrw/5r1rdHKmbaXvtJv9JUxxL+PKbRfto9yhAZsxSTb1gfKONXir0XrPb0jXdaYyHssRtujxge2s/+wu0w4H7jetN+/oU+2hz/GcWIp4xpMiZGbQ0TkV6+ptVWUZR3CR3O3ZVTSpnk5q9cVZWUEUlwj0pRiZw9JhRtIuQfC3ctHSLx6hWl2PWQ1uGcSrlykdfh3IWvQzJgPVEIXeIOMkN3kwajwzlyA1wmFrz7DNyXS6Di3YNaCXc4Hc4xDyNFS5N3rjwdPVKHc7yGEWoQokkgOf0VVn4HG4RmEmjImuEELmAOWeDkEki1uKZi6ADH3hlGBAaVvWsYRTCsXHxlwOuAJ5EZfCoBdOqfwHfv8Gw4A8+JJUeHc+j+iuQieCeB9ervoHt3Qn0yg65SKOlwAp0SCYXWDLrcYulwDquDFn3R8bfmCcGORBC6wwVsl3gaIbTEjk7tlPZwBtsknsYip/GR0wg5TR45TYlynqKR1LLjm/bT9COk0yD8edBpDh9OcxzEClv4DwukYxT8px5S/Yv/QEJyEsJECiUlMr7rUg5NGcNOlHeLMutEqFI4c3SEuEUaq4HnRMpn9oLg7qy5RtxA4wxvrBFcy/PmsTHDywvMIWaol1Anf4F1CnE2s4Ae1JGv7sPaEvZNPpS/868r1JBkMijcQYaUXCqXXQFuonTVVTwGcyPvE2mH17tS2Yk6/KC4/KWTvOKqusSmFlNSKS9/kFKiraMobiJKKgN7HySuUOteZv8jOTOaWPkwcUl6vSqFC7p7lAmHdq2N12ohdjeKlZ0oT25RnjIaiFYbuuDwdbW6ke4S5CqtISff0Hi7ymB24VlR9mNQGK7G3lbA+qVsonaL3I1tb/PdBfgJO/sB67A3aks1qpe+P1xE1tXctSPYRW6bk6aUXnYJkpazyFnjT4qGVW6Qr9QtvfaKX8z4HfLaxph1n74Q14KmtFE+sFqttMbWB07zSxmhwx9H1KxLx+CqJXVtqT/YZp42vjwBDMS0i7ozKEeRXS/pA+YkVe4Lgj+IM3oNHQglOjrklWjpkFYi+a0wWIngcaSePX6ViNkEOzDnoUQoCvPzxztC+YR2P2wfkclscl3yGYFqhbbR5TvJZ/fEW8bfSQzC2gHrSWLoMuDoC0kOb8RBZhLcBDOAGUvC4KZ6JlwTPSlI7dB9iOzibb1YE5Evl6GItRAVuYi7XPyJOOyykwpfiUiLJmrFLcHVI/pCWCzBF8mMGiTYJFYNEmwSswYJNMnNrEF+TBLy4dewQYJMYtdDJgK8xFy1uMa/djSZ1J943xInLpqLw/frtcGyd41nEUzcVxqLn7sbd/UJP3c31ql/wqt7Jy7+i8en5zV1lrWHzxmX8E8OMXj8OvF/ELMmjuOWyTOHLcenEOaz4cxxTjRd+D7Z/KDkH+MbT03dnEr6AAAAAElFTkSuQmCC" 
                        }
                        alt="" />
                    </div>
                    <div className="right">
                        <form >
                        <div className="formInput">
                                
                                <label htmlFor="img">Image: <DriveFolderUploadOutlinedIcon className="icon"/></label>
                                <input 
                                type="file" 
                                id="img" 
                                name="img"
                                onChange={(e)=>setFile(e.target.files[0])}
                                style={{display:"none"}}/>
                            </div>

                            {inputs["formInputs"].map((input) =>(
                                <div className="formInput" key={input.id}>
                                    <label>{input.label}</label>
                                    <input 
                                    name={input.id} 
                                    type={input.type} 
                                    placeholder={input.placeholder} 
                                   
                                    onChange={handleChange}/>
                                </div>
                            ))}

                            <button onClick={handleSubmit}>Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}