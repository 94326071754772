import { useState } from 'react'
import {useAuth} from '../../context/authContext'
import {useNavigate, Link} from 'react-router-dom'
import { Alert } from '../../components/alert/Alert';
export function Register() {

    const [user, setUser] = useState(
        {
            email: '',
            password: ''
        }
    );

    const {signup} = useAuth();
    const navigate = useNavigate();
    const [alert, setAlert] = useState({});
    const handleChange = ({target: {name, value}}) => {
        //console.log(name, value);
        setUser({...user,[name]: value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(user);
        setAlert({});
        
        try{
            await signup(user.email, user.password);
            navigate('/');
        }
        catch(error){
            console.log(error.code);
            if(error.code === "auth/weak-password"){
                console.log("check");
                setAlert({"message":"Invalid password","type":"error"});
            }
            else{
                setAlert({"message":error.message,"type":"error"});

            }
        }

    }
    return (
        <div className="w-full max-w-xs m-auto">
        {alert && <Alert alert={alert} />}
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                <input 
                type="email" 
                name="email" 
                placeholder="youremail@company.ltd" 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-ouline"
                onChange={handleChange} 
                />
            </div>
            <div className="mb-4">
                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                <input 
                type="password" 
                name="password" 
                id="password" 
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-ouline"
                onChange={handleChange} 
                placeholder = "******"
                />
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Register</button>
        </form>

        <p className="my-4 text-sm flex justify-between px-3">Already have an account? <Link to='/login'>Login</Link></p>

        </div>
    )

}
