export const userInputs = {

"form":"users",
"title":"Users",
"key":"uid",
"name" : "name",
"formInputs":[
    {
        id: "username",
        label: "Username",
        type: "text",
        placeholder: "carinae",
        required:true
    },
    {
        id: "name",
        label: "Name and surname",
        type: "text",
        placeholder: "Alpha Carinae",
        required:true
    },
    {
        id: "email",
        label: "Email",
        type: "mail",
        placeholder: "carinae@alphaustralis.com",
        required:true
    },
    {
        id: "phone",
        label: "Phone",
        type: "text",
        placeholder: "+1 234 567 89",
        required:true
    },
    {
        id: "address",
        label: "Address",
        type: "text",
        placeholder: "Elton St. 216 New York",
        required:true
    },
    {
        id: "country",
        label: "Country",
        type: "text",
        placeholder: "USA",
        required:true
    },

]};

export const productInputs = {

"form":"products",
"title":"Add New Product",
"key":"sku",
"name" : "title",
"formInputs":
[
    {
        id:"sku",
        label:"SKU",
        type:"text",
        placeholder:"001-001-001-001-001",
        required:true
    },
    {
        id:"title",
        label:"Title",
        type:"text",
        placeholder:"Apple Macbook Pro",
        required:true
    },
    {
        id:"description",
        label:"Description",
        type:"text",
        placeholder:"Description",
        required:true
    },
    {
        id:"category",
        label:"Category",
        type:"text",
        placeholder:"Computers",
        required:true
    }
    ,
    {
        id:"brand",
        label:"Brand",
        type:"text",
        placeholder:"Apple",
        required:true
    }
    ,
    {
        id:"model",
        label:"Model",
        type:"text",
        placeholder:"Macbook Pro",
        required:true
    }
    ,
    {
        id:"color",
        label:"Color",
        type:"text",
        placeholder:"White",
        required:true
    }
    ,
    {
        id:"price",
        label:"Price",
        type:"text",
        placeholder:"100",
        required:true
    },
    {
        id:"stock",
        label:"Stock",
        type:"text",
        placeholder:"in stock",
        required:true
    }
]
}

export const plansInputs = {

    "form":"plans",
    "tenant": "yes",
    "title":"Linear Plans",
    "key":"id",
    "name" : "id",
    "formInputs":
    [
        
    ]
    }
    export const itemsInputs = {

        "form":"items",
        "tenant": "no",
        "title":"Items",
        "key":"item",
        "name" : "item",
        "formInputs":
        [
            
        ]
        }