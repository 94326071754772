// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAO5bpy1aj-1Pwqn-EqXfte2jqOq5PyMM4",
  authDomain: "pegasi-f7779.firebaseapp.com",
  projectId: "pegasi-f7779",
  storageBucket: "pegasi-f7779.appspot.com",
  messagingSenderId: "265469820037",
  appId: "1:265469820037:web:f3974e75307da5257dbdec"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
